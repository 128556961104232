@import url('https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200..800;1,200..800&family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200..800;1,200..800&family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

:root {
    /* text styles */
    --white: rgba(209, 207, 204, 0.87);
    --html: #e34c26;
    --css: #264de4;
    --javascript: #323330;
    --mongodb: #4DB33D;
    --react: #4c768d;
    --oracle: #c74634;
    --java: #5382a1;
    --firebase: #f5820d;
}

* {
    /* font-family: 'Arial', sans-serif; */
    font-family: "Noto Sans", sans-serif;
    margin: 0;
    /* padding: 0; */
    box-sizing: border-box;
    max-width: 100%;
}

body {
    margin: 0 auto;
    /* background: #ededed; */
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
}

.app {
    width: 100vw;
}

/* button styles */
.btn {
    display: inline-block;
    background-color: #A91D3A;
    color: #fff;
    border: 1.2px solid #fff;
    /* padding: 14px 20px; */
    border-radius: 4px;
    transition: background-color 0.3s ease;
    cursor: pointer;
}

.btn:hover {
    background-color: #555;
}

.main-container {
    /* border: 2px solid red; */
    width: calc(90% - 0px);
    /* width: 88%; */
    /* width: 90%; */
    overflow: hidden;
    /* margin: 5rem auto; */
    /* margin: 56.2256px auto 3.2rem auto; */
    /* margin: 3% auto 0 auto; */
    /* margin: 2.2% auto 3.2rem auto; */
    margin: 34px auto 3.2rem auto;
    /* border-radius: 4px; */
    background-color: transparent;
    box-shadow: 0 4px 30px rgba(1, 1, 1, .04);
}

a {
    text-decoration: none;
    color: #333;
}

header {
    /* border: 2px solid red; */
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: #A91D3A; */
    background-color: rgba(169, 29, 58, .85);
    color: var(--white);
    padding: 28px 0;
    box-shadow: 0 2px 4px rgba(1, 1, 1, .1);
}

.logo {
    flex: 1;
    /* position: absolute;
    left: 4px;
    top: 4px; */
    color: #fff;
    font-size: 0.9rem;
}

/* .logo img {
    max-width: 100%;
    max-height: 75px;
    width: 120px;
} */

nav {
    /* border: 2px solid red; */
    position: fixed;
    /* width: 100%; */
    /* max-width: 1024px; */
    /* width: 88%; */
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
}

nav ul {
    list-style-type: none;
    display: flex;
    gap: 26px;
    padding: 0 14px;
}

.nav-links {
    font-size: 15px;
    padding: 12px 0;
    margin-left: 20px;
    cursor: pointer;
}

nav a {
    font-size: 16px;
    color: #fff;
    transition: color .3 ease-in-out;
}

nav a:hover {
    /* color: #ccc; */
    color: #fbdabb;
}

.nav-social-media {
    width: 100%;
    margin-top: 1.2rem;
    display: flex;
    align-items: center;
    gap: 8px;
    /* border: 2px solid red; */
}

.nav-social-media i {
    /* font-size: 36px; */
    transition: color .3 ease-in-out;
}

.nav-social-media i:hover {
    color: #fff;
}

.nav-social-media-icon {
    /* border: 2px solid red; */
    font-size: 60px;
}

/* About section */
.about {
    /* border: 2px solid red; */
    width: 100%;
    min-height: 95vh;
    background: transparent;
    display: flex;
    align-items: center;
}

.about-content {
    /* border: 2px solid red; */
    min-height: 95vh;
    /* padding: 0 80px; */
    color: #333;
    width: 100%;
    margin: 0 auto;
    display: flex;
}

.about-bio {
    /* border: 2px solid red; */
    background: #fff6f8;
    padding: 2.6rem 80px;
    width: 100%;
    min-height: 100%;
    /* flex: 8; */
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.about-bio p {
    max-width: 650px;
    font-size: 18px;
    margin-bottom: 3rem;
}

.about-bio a {
    padding: 0.8rem 1.1rem;
}

.about-profileImg {
    /* border: 2px solid red; */
    padding: 6rem 1rem;
    background-color: #A91D3A;
    width: 100%;
    /* height: 88%; */
    /* flex: 10; */
    color: #ffd2e0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.about-profileImg-content {
    /* border: 2px solid red; */
    padding: 0 1rem;
    height: 70%;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
}

.about-profileImg-content-details {
    line-height: 2;
}

.about-profileImg-content h1 {
    font-size: 2.1rem;
    /* text-decoration: underline;
    text-underline-offset: 14px; */
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    margin-bottom: 2.5rem;
}

.about-profileImg-content-details p {
    color: #fdfdfd;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 1.125rem;
    margin-bottom: 1rem;
}

.about-profileImg-btn a {
    margin-top: 1.2rem;
    font-size: 1rem;
    padding: 0.4rem 0.8rem;
}

/* NOT USED */
.about-profileImg-img {
    background-color: #cdcdcd;
    width: 100%;
    height: 380px;
    border-radius: 100%;
    text-align: center;
}

.about-profileImg-img img {
    overflow: hidden;
    border-radius: 100%;
}

.about-text {
    /* border: 2px solid red; */
    height: 60%;
    padding: 6rem 0 0 0;
    text-transform: uppercase;
    line-height: 1.2;
    text-align: left;
}

.about-text h1:nth-of-type(1) {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 62px;
    margin: 0;
    padding: 0 4px;
}

.about-text h1:nth-of-type(2) {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 56px;
    margin: 0;
    padding: 0;
}

.about-bio p {
    font-size: 1rem;
    line-height: 1.6;
}

.about-description {
    /* border: 2px solid red; */
    padding: 0 1rem 0 0;
    font-size: 1rem;
    margin-top: 2.2rem;
}

.about-email h1 {
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    font-size: 1.5rem;
    text-transform: uppercase;
}

.about-email a {
    margin: 0;
    padding: 0;
}

.about-email p {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 1rem;
    color: #A91D3A;
}

.about-social-links {
    /* border: 2px solid red; */
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    position: absolute;
    z-index: 50;
    top: calc(50vh - 65px);
    left: 88%;
}

.about-social-links .icon {
    /* border: 2px solid red; */
    color: #48486c;
    font-size: 1.8rem;
    cursor: pointer;
    transition: color 0.3s ease;
}
.about-social-links .icon:hover {
    color: #A91D3A;
}

/* work section */
.work {
    padding: 6rem 80px 1rem 80px;
    display: flex;
    flex-direction: column;
    /* background: #e9e9e9; */
    background: #fefefe;
}

.work h2 {
    font-size: 32px;
}

.work-content {
    margin: 2.2rem 0;
    padding: 0 20px;
}

.work-card {
    /* background: #fff; */
    background: #fcfcfc;
    box-shadow: 0 4px 8px rgba(1, 1, 1, .15);
    border-radius: 4px;
    padding: 30px;
    margin-bottom: 2.2rem;
    line-height: 1.5;
}

.work-card h3 {
    font-size: 26px;
    font-weight: 400;
    margin-bottom: 0.5rem;
}

.work-card span {
    font-size: 1rem;
    color: #888;
    margin-bottom: rem;
}

.work-card a {
    font-size: 1.2rem;
    color: #A91D3A;
    font-weight: bolder;
    border-radius: 4px;
}

.work-card .work-details {
    margin-top: 1.1rem;
    padding: 0 10px;
}

/* projects section */
.projects {
    /* border: 2px solid red; */
    width: 100%;
    background: #fdfdfd;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 6rem 0;
}

.projects h2 {
    font-size: 1.9rem;
    /* font-size: 2.3rem; */
}

.projects-content {
    /* border: 2px solid red; */
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 40px;
    /* padding: 60px 80px; */
    padding: 60px 0;
}

.project-card {
    /* border: 2px solid red; */
    /* width: 410px; */
    /* width: 407px; */
    /* width: 380px; */
    /* width: 100%; */
    max-width: 380px;
    min-height: 800px;
    border-radius: 20px;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(1, 1, 1, .2);
    transition: all .4 ease-in-out;
}

.project-card:hover {
    transform: scale(1.04);
    box-shadow: 0 4px 8px rgba(1, 1, 1, .2);
}

.project-img {
    /* width: 100%; */
    /* height: 320px; */
    height: fit-content;
    background: #cdcdcd;
    border-radius: 20px;
}

.project-img img {
    width: 100%;
    max-width: 100%;
    height: 250px;
    object-fit: cover;
    border-radius: 20px;
}

.project-content {
    padding: 20px;
    line-height: 1.5;
}

.project-content h3 {
    font-size: 20px;
    /* margin-bottom: 0.4rem; */
    margin-top: 0.4rem;
    ;
    padding: 1px;
}

.project-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
}

.project-content .tag {
    border: 1px solid #A91D3A;
    color: #A91D3A;
    padding: 4px 8px;
    font-size: 0.85rem;
    border-radius: 12px;
}

.project-feature {
    /* border: 2px solid red; */
    margin-top: 1rem;
    max-width: 100%;
    padding: 0 1rem;
}
.project-feature li {
    margin-bottom: 0.7rem;
}

.project-content a {
    margin-top: 1.2rem;
    padding: 0.4rem 0.8rem;
}


/* contacts section (UNUSED) */
.contacts {
    margin: 2rem 0;
    padding: 6rem 80px;
    display: flex;
    flex-direction: column;
    background: #f3f3f3;
}
.contacts h1 {
    font-size: 32px;
    text-align: center;
    margin-bottom: 2rem;
}
.contacts form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}
.contacts label {
    font-weight: bold;
    font-size: 1.1rem;
}
.contacts input,
.contacts textarea {
    background-color: transparent;
    width: 100%;
    padding: 0.8rem;
    border: none;
    outline: 1.6px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
    color: #111;
}
.contacts select {
    margin-left: 8px;
    padding: 2px 14px;
    font-size: 18px;
    border-radius: 4px;
    outline: 1.6px solid #ccc;
    border: none;
    color: #111;
}
.contacts textarea {
    resize: vertical;
}
.contacts button {
    padding: 0.8rem 1.5rem;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
}

/* RESPONSIVE */
/* Mobile */
@media (max-width: 994px) {
    body {
        font-size: 14px;
    }
    .main-container {
        width: 100%;
    }
    .logo {
        font-size: 0.75rem;
    }

    .about-content {
        flex-direction: column;
    }

    .about-profileImg-content {
        padding: 0 1rem;
        align-items: center;
        justify-content: center;
    }

    .about-profileImg-content h1 {
        font-size: 1.8rem;
    }

    .about-profileImg-content-details p {
        font-size: 0.8rem;
    }

    .about-profileImg-btn button {
        font-size: 0.9rem;
    }

    .about-text {
        text-align: center;
    }

    .about-text h1:nth-of-type(1) {
        /* text-align: center; */
        font-size: 2rem;
    }

    .about-text h1:nth-of-type(2) {
        font-size: 2.2rem;
    }

    .about-bio {
        padding: 2.6rem 3rem;
    }

    .about-bio p {
        font-size: 0.6rem;
    }

    .about-description {
        text-align: center;
    }

    .about-social-links {
        /* border: 2px solid red; */
        position: relative;
        top: 0;
        left: 0;
        flex-direction: row;
        padding: 4rem 80px;
        align-items: center;
        justify-content: center;
        gap: 3.2rem;
    }

    .about-social-links .icon {
        font-size: 3rem;
    }

    .about-email {
        text-align: center;
    }
    .about-email p {
        font-size: 0.9rem;
    }
    #skills .skills {
        padding: 0 4%;
        transform: scale(0.9);
    }

    .projects {
        padding: 6rem 6%;
    }

    .project-card {
        width: 100%;
        max-width: none;
        min-height: fit-content;
    }

    .skills {
        text-align: center;
        padding: 6rem 0 1rem 0;
        min-height: none;
    }

    .skills-container {
        margin-top: 2.2rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 0;
    }

    .skill {
        width: auto;
        padding: 0;
        margin-right: 0;
        /* border: 2px solid red; */
        /* transform: scale(0.9); */
    }
    .skill-details {
        text-align: left;
    }
}

/* Tablet */
@media (min-width: 994px) and (max-width: 1000px) {
    body {
        font-size: 15px;
    }
    
    .skills-container {
        flex-direction: column;
        gap: 20px;
    }

    .skill {
        width: calc(50% - 10px);
    }

    .about-content {
        flex-direction: row;
    }

    .about-social-links {
        /* border: 2px solid red; */
        position: relative;
        top: 0;
        left: 0;
        flex-direction: row;
        padding: 4rem 20px;
        align-items: center;
        justify-content: center;
        gap: 3.2rem;
    }

    .about-social-links .icon {
        font-size: 2.8rem;
    }
}

/* Desktop */
@media (min-width: 1024px) {
    body {
        font-size: 16px;
    }

    .skills-container {
        flex-direction: row;
        gap: 20px;
    }

    .skill {
        width: calc(25% - 10px);
    }

    .about-social-links {
        padding: 4rem 40px;
    }
}

@media (min-width: 1024px) and (max-width: 1025px) {
    .about-content {
        flex-direction: column;
    }
    .about-social-links {
        /* border: 2px solid red; */
        position: relative;
        top: 0;
        left: 0;
        flex-direction: row;
        padding: 4rem 20px;
        align-items: center;
        justify-content: center;
        gap: 3.2rem;
    }

}

