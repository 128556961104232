.skills {
    /* border: 2px solid red; */
    padding: 6rem 80px 1rem 80px;
    display: flex;
    flex-direction: column;
    min-height: 80vh;
}
.skills h1 {
    font-size: 2.3rem;
}
.skills p {
    color: #A91D3A;
}
.skills-container {
    /* border: 2px solid red; */
    display: flex;
    /* align-items: center; */
    align-items: stretch;
    justify-content: center;
    gap: 2rem;
}
.skill {
    /* border: 2px solid red; */
    width: 100%;
    min-height: fit-content;
    /* height: 450px; */
    padding: 2rem 0;
    margin-right: 1rem;
    display: flex;
    flex-direction: column;
    flex: 1;
}
.skill i {
    font-size: 3rem;
    margin-bottom: 1rem;
}
.skill h1 {
    font-size: 1.5rem;
    margin-bottom: 2.3rem;
}
.skill h2 {
    font-size: 1.2rem;
    font-weight: 300;
    color: #616161;
    margin-bottom: 1.8rem;
}
.skill a {
    color: #A91D3A;
    transition: color .3 ease;
    margin-bottom: 1.8rem;
}
.skill a:hover {
    color: #555;
}
.skill-details {
    /* border: 2px solid red; */
    padding: 2rem;
}
.skill-details li {
    line-height: 1.5;
    margin-bottom: 0.7rem;
}
.line {
    width: 100%;
    height: 2px;
    background-color: rgba(169, 29, 58, .6);
    border-radius: 4px;
    margin-top: auto;
}
