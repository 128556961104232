footer {
    /* border: 2px solid red; */
    height: max-content;
    padding: 5rem 0;
    font-family: 'Karla', sans-serif;
    background: #A91D3A;
    color: #edefee;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

/* GRID */
.footer-container {
    /* border: 2px solid red; */
    /* width: 88%; */
    width: 90%;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
}

/* PHOTO */
.footer-grid-photo-container {
    /* border: 2px solid red; */
    grid-column: 3 / span 2;
    /* grid-column: span 2 / 3; */
}

.footer-grid-photo-container img {
    object-fit: cover;
    /* height: 150px;
    width: 150px; */
    width: 50%;
    height: 50%;
    border-radius: 100%;
}

/* NAV */
.footer-grid-nav-container {
    /* border: 2px solid red; */
    grid-column: 5;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    list-style: none;
    margin-bottom: 1rem;
}
.footer-grid-nav-container a {
    /* padding: 0; */
    margin: 0;
    color: #edefee;
}

.footer-nav-title, 
.footer-contact-title, 
.footer-socials-title {
    font-weight: 800;
}

.footer-grid-nav-container .nav-links {
    text-transform: capitalize;
    padding: 0;
    cursor: pointer;
}

.footer-grid-nav-container .nav-links:hover {
    color: #fbdabb;
}

/* CONTACT */
.footer-grid-contact-container {    
    /* border: 2px solid red;; */
    grid-column: 7;
    display: flex;
    flex-direction: column;
    /* gap: 1rem; */
    font-style: italic;
}

.contacts {
    /* border: 2px solid red; */
    /* display: flex;
    flex-direction: column; */
    background-color: transparent;
    gap: 1rem;
    list-style: none;
    padding: 1rem 80px 1rem 0;
    margin: 0;
}
.contacts a {
    padding: 0;
    margin: 0;
}

.contact-links {
    color: #edefee;
}

/* SOCIAL */
.footer-grid-socials-container {
    grid-column: 8 / span 4;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    list-style: none;
}
.footer-grid-socials-container .icon {
    font-size: 1.8rem;
    color: #edefee;
    transition: color .3 ease-in-out;
}
.footer-grid-socials-container .icon:hover {
    color: #fbdabb;
}

.social-links {
    color: #edefee;
}

.social-links:hover {
    color: #fbdabb;
}

/* COPYRIGHT */
.copyright {
    margin-top: 3rem;
    font-size: 14px;
    text-align: center;
    color: #edefee;
}

.copyright-link:link,
.copyright-link:visited {
    color: #edefee;
    text-decoration: underline;
    text-underline-offset: 5px;
    transition: all .2;
}

.copyright-link:hover,
.copyright-link:hover {
    color: #fbdabb;
}

/* RESPONSIVE */
@media (max-width: 768px) {

    /* FOOTER */
    .footer-container {
        grid-template-columns: repeat(2, 1fr);
    }

    .footer-grid-photo-container img{
        display: none;
    }

    .footer-grid-nav-container{
        grid-column: 1;
    }

    .footer-grid-contact-container {
        grid-column: 1;
    }

    .footer-grid-socials-container {
        grid-column: 1;
    }
}